import Layout from "../components/layout"
import {graphql} from 'gatsby'
import React from 'react'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import Link from 'gatsby-link'
import BodyClassName from 'react-body-classname';
import PageTransition from 'gatsby-v2-plugin-page-transitions'
import ScrollAnimation from 'react-animate-on-scroll';


export default class IndexPage extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.articles
    const { edges: trips } = data.trips
    const { edges: artwork } = data.artwork
    return (
      <Layout>
      <PageTransition>
      <BodyClassName className="home">
      <div className="page">
      <article className="article">
        <ScrollAnimation animateIn="fadeInUp2" duration={.5}>
          <h1>Hi, I’m John</h1>
        </ScrollAnimation>

          <ScrollAnimation animateIn="fadeInUp2">
          <section className="introSection">
          <p>
          I’m John Dilworth, not to be confused with the legendary animator of the same name (<a href="http://www.stretchfilms.com/" className="link">John R. Dilworth</a>) whose fan mail I occasionaly receive, or my distant cousin who uses my name and email address to sign up for <em>every single</em> dating site in the UK.
          </p>
          <p>
          I am a <strong>designer</strong> and an <strong>artist</strong>. Some have issues with that, but I have problems trying to keep the two separate. I even like to cross over to the dark side and write <strong>code</strong>. I think design and art happens in any medium—code editors included.
          </p>
          <p>
          I’m also a <strong>manager</strong>. I find most corporate manager-ly things thoroughly annoying. However, I care lots about people, and the most rewarding benefits of <Link to="/resume/" className="link"><strong>my career</strong></Link> have been watching people I’ve had the opportunity to “manage” grow into amazing designers and leaders.
          </p>
          <p>
          I’m currently working at <a href="http://lucid.co" className="link" rel="external"><strong>Lucid Software</strong></a> as <strong>Sr. Director of User Experience.</strong>
          </p>
          <p>I’d love to see job titles, corporate hierarchies, and everything that goes with them go away.
          I do a little directing, but mostly I like working with a team to make stuff better and <Link to="/posts/make-it-beautiful" className="link"><strong>more beautiful</strong></Link>.</p>
          <p> I have learned a few things in my career, including the fact that ideas are never good because of someone’s position. I have also learned that sometimes what we really need is a little <Link to="/posts/less-action" className="link"><strong>less action</strong></Link>, and a lot <Link to="/posts/more-chaos" className="link"><strong>more chaos</strong></Link>.
          </p>
          </section>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp2">

          <section className="articleSection mt-100">
          <div className="mt-60">


          <h2>Writing on Design</h2>
          <p>
          “Designing” is a complicated job, and designers everywhere are all trying to make sense of it.
          </p>
          <p> I've written some perspectives I've had about design and creativity, along with a few other stories I hope people find entertaining.</p>

          </div>
          <div className="articleList">
          {posts
            .map(({ node: post }) => (
              <div className="grow" key={post.id}>
              <Link
                to={post.fields.slug}                
              >
              <div className="postcard" style={{}}>
                <div className="cardthumb">
                  <img alt="" src={post.frontmatter.thumb.childImageSharp.fluid.src} />
                </div>
                <div className="cardtitle">
                <h3 className="no-margin">
                    {post.frontmatter.title}
                </h3>
                <p className="opacity-half description line-clamp-3">
                   {post.frontmatter.description}
                </p>
                </div>
              </div>
              </Link>
              </div>
            ))}
            </div>

            <p><Link to="/articles" className="link">View all posts →</Link></p>



            </section>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInUp2">
            <section className="tripSection mt-100">
            <div style={{"width":"100%"}}  className="mt-60">


            <h2>Travel & Exploration</h2>
            <p>
            As much as I like my job and the corporate posturing required to move up the career ladder, I love exploring the world even more.</p>
            <p>I’m working out how I can make my job duties exclusive to exploring the great outdoors, visiting the world’s most beautiful places, investigating art museums, and cultural history. </p>


              <div className="tripGrid">
                {trips
                  .map(({ node: trip }) => (
                    <div className="trip grow"  key={trip.id}>
                      <Link to={trip.fields.slug}>
                      <Img alt="" fluid={trip.frontmatter.thumb.childImageSharp.fluid} />
                      <h2>{trip.frontmatter.title}</h2>
                      </Link>
                    </div>
                  ))}
              </div>

              <p><Link to="/trips" className="link">View all trips →</Link></p>

              </div>

              </section>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp2">
              <section className="artworkSection mt-100">
              <h2>Other Creative Outlets</h2>
              <p>I don’t entirely separate art from my design work. I do enjoy expressing ideas that have nothing to do with my job artistically.</p>
              <p>Creating art is therapeutic, and I just love doing it. </p>
              <p>While most design work you do for a company is ephemeral and will be gone in 5–7 years,  art can last generations.</p>
              <p>The latest art series I have been working on explores famous inventors and their relationship with their technology. Sometimes it’s a friend, and sometimes it’s a monster.</p>
              {artwork
                .map(({ node: art }) => (
                  <div className="row artworkList" key={art.id}>
                <Link
                  to={art.fields.slug}
                  key={art.id}
                  className="col-xs-12 col-sm-7"
                  style={{"padding":"20px"}}
                >
                <div className="art" >
                  <Img fluid={art.frontmatter.thumb.childImageSharp.fluid} />
                </div>
                </Link>
                <div className="col-xs-12  col-sm-5">
                <h3>{art.frontmatter.title}</h3>
                <p>{art.frontmatter.description}</p>
                </div>
                </div>
              ))}
              <p><Link to="/collections" className="link">View all artwork →</Link></p>


                </section>
                </ScrollAnimation>
</article>
              </div>

      </BodyClassName>
      </PageTransition>
      </Layout>
    )
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    articles: PropTypes.shape({
        edges: PropTypes.array,
    }),
    trips: PropTypes.shape({
        edges: PropTypes.array,
    }),
    artwork: PropTypes.shape({
        edges: PropTypes.array,
    }),
  }),
}

export const IndexQuery = graphql`
  query IndexQuery {
    articles:allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date, frontmatter___title]},
      filter: { frontmatter: { templateKey: { eq: "blog-post" }, tags: { in: "ML" }, published: {ne: false} }},
      limit: 4
    ) {
      edges {
        node {
          excerpt(pruneLength: 100)
          timeToRead
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            description
            date(formatString: "MMMM DD, YYYY")
            path
            thumb{
              childImageSharp{
                fluid(maxWidth: 500){
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    trips:allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date, frontmatter___title]},
      filter: { frontmatter: { templateKey: { eq: "trip" } }},
      limit: 8
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            thumb{
              childImageSharp{
                fluid(maxWidth: 500){
                  ...GatsbyImageSharpFluid
                }
              }
            }
            templateKey
            date(formatString: "MMMM DD, YYYY")
            path
          }
        }
      }
    }
    artwork:allMarkdownRemark(
        sort: { order: ASC, fields: [frontmatter___order]},
        filter: { frontmatter: { templateKey: { eq: "artwork" }, collection: { eq: "inventors" } }},
        limit: 1
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              title
              description
              templateKey
              date(formatString: "MMMM DD, YYYY")
              path
              thumb{
                childImageSharp{
                  fluid(maxWidth: 500){
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
  }
  `
